<template>
  <client-only>
    <EpiEditButton
      v-if="showEditButton && page && page.Content && page.Content.items"
      :page-id="page.Content.items[0].ContentLink?.Id.toString()"
      class="mobileOnly:!hidden"
    />
  </client-only>
  <div
    v-if="pageType === 'Startpage'"
    class="default-vue"
    @click="counter += 1"
    @mouseleave="mouseleave"
  >
    <AgeGate
      v-if="showAgeGate"
      :image-url="ageGateImageUrl"
      :cancel-url="ageGateUnder18LinkUrl"
      @close="closeAgeGate"
    />
    <main v-show="!showAgeGate" class="main">
      <slot />
    </main>
  </div>
  <div
    v-else
    class="default-vue"
    :class="{
      'market-landing-page': pageType === 'MarketLandingPage',
      'bg-grey200':
        isAgeVerificationPage || (isCheckout && config.theme === 'thunder'),
    }"
    @click="counter += 1"
    @mouseleave="mouseleave"
  >

    <AgeGate
      v-if="showAgeGate && !isEditMode"
      :image-url="ageGateImageUrl"
      :cancel-url="ageGateUnder18LinkUrl"
      @close="closeAgeGate"
    />
    <div>
      <PageHeader
        v-if="!isCheckout && !useCampaignHeader && !isAgeVerificationPage"
        :take-over-header="useTakeOverHeader"
        :test-mode-active="testModeActive"
        :is-mobile="isMobile"
        :is-tablet="isTablet"
        @deactivate-test-mode-click="deactivateTestMode"
      />
      <CampaignHeader
        v-if="useCampaignHeader"
        :take-over-header="true"
        :is-mobile="isMobile"
        :test-mode-active="testModeActive"
        @deactivate-test-mode-click="deactivateTestMode"
      />
      <div
        class="relative flex-1"
        :class="{
          '-mt-80': useTakeOverHeader || useCampaignHeader,
        }"
      >
        <transition name="snack-bar">
          <ErrorBar
            v-if="shouldShowErrorBar"
            :is-error="true"
            :text="errorBarText"
            :static-error-bar="isCheckout || isAgeVerificationPage"
            :using-usps="config.currentMarket?.marketUsps && config.currentMarket?.marketUsps.length > 0"
            @closeSnackBar="closeErrorBar"
          />
        </transition>
        <div
          :class="{
            'bg-grey200':
              isAgeVerificationPage || (isCheckout && config.theme === 'thunder'),
            'min-h-screen-default': !isEditMode,
            '!h-full !min-h-24 !max-h-[20000px]': isEditMode,
            'bg-grey500': page?.Theme === 'ZynTheme' && !isAgeVerificationPage,
            'bg-background': page?.Theme === 'ZynTheme' && !isCheckout && !isAgeVerificationPage,
          }"
        >
        <main class="main">
            <slot />
          </main>
        </div>
        <Overlay
          class="z-overlay pt-80"
          :overlay-active="isPopupBoxOpen"
          @close-overlay="closeOverlay"
        ></Overlay>
        <PopUpBox
          v-if="isPopupBoxOpen"
          :offset="popUpOffset"
          :has-market-usps="
            config.currentMarket?.marketUsps &&
            config.currentMarket?.marketUsps.length > 0
          "
        >
          <Basket
            v-if="popUpType === PopUpTypes.Basket"
            @close-overlay="closeOverlay"
          />
          <ItemAddedDisplay
            v-else-if="popUpType === PopUpTypes.BasketItemAdded"
            :data="popUpData"
            @close-overlay="closeOverlay"
          />
          <ItemAddedDisplay
            v-else-if="popUpType === PopUpTypes.SubscriptionItemAdded"
            :data="popUpData"
            subscription
            @close-overlay="closeOverlay"
          />
          <Basket
            v-else-if="popUpType === PopUpTypes.Subscription"
            :purchase-type="PurchaseType.Subscription"
            @close-overlay="closeOverlay"
          />
        </PopUpBox>
        <transition name="slide-fade">
          <PopUpAddedToCartBox
            v-if="isPopupAddedToCartBoxOpen"
            @clear-timeout="clearPopupTimeout"
            @set-timeout="setPopupTimeout"
          >
            <ItemAddedDisplay
              v-if="popUpType === PopUpTypes.BasketItemAdded"
              :data="popUpData"
              @close-overlay="closeOverlay"
            />
            <ItemAddedDisplay
              v-else-if="popUpType === PopUpTypes.SubscriptionItemAdded"
              :data="popUpData"
              subscription
              @close-overlay="closeOverlay"
            />
          </PopUpAddedToCartBox>
        </transition>
        <SelectMarketModal
          v-if="config.markets && config.markets?.length > 1"
          v-show="marketModalOpen"
          :is-open="marketModalOpen"
          :markets="config.markets"
          @close-market-modal="onCloseMarketModal"
        />
        <WrongCountryModal
          v-if="wrongCountryModalOpen"
          @close-wrong-country-modal="onCloseWrongCountryModal"
        />
      </div>
      <NewsletterModal
        v-if="
          showNewsletterModalLoaded &&
          showNewsletterModal &&
          ((triggerNewsletterModal && !newsletterModalHaveBeenClosed) ||
            (cursorLeftScreen && !newsletterModalHaveBeenClosed))
        "
        :is-mobile="isMobile"
        :is-open="
          (triggerNewsletterModal && !newsletterModalHaveBeenClosed) ||
          (cursorLeftScreen && !newsletterModalHaveBeenClosed)
        "
        @close-newsletter-modal="onCloseNewsletterModal"
      />
      <PageFooter
        v-if="!isCheckout && config.currentMarket && !isAgeVerificationPage"
        ref="footer"
        :is-mobile="isMobile"
        @setTestMode="setTestMode"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useGlobalContentStore } from '~/store/globalContent';
import { usePageContentStore } from '~/store/pageContent';
import { storeToRefs } from 'pinia';
import { useUiStore } from '~/store/ui';
import PageHeader from '~/components/body/PageHeader.vue';
import PageFooter from '~/components/body/PageFooter.vue';
import CampaignHeader from '../components/body/CampaignHeader.vue';
import PopUpTypes from '~/constants/pop-up-types';
import { PurchaseType } from '../models/api-types';
import NewsletterModal from '../components/modals/NewsletterModal.vue';
import AgeGate from '../components/AgeGate.vue';
import PopUpBox from '~/components/PopUpBox.vue';
import PopUpAddedToCartBox from '~/components/PopUpAddedToCartBox.vue';
import ItemAddedDisplay from '~/components/ItemAddedDisplay.vue';
import ErrorBar from '~/components/ErrorBar.vue';
import SelectMarketModal from '~/components/SelectMarketModal.vue';
import Overlay from '~/components/body/Overlay.vue';
import WrongCountryModal from '~/components/WrongCountryModal.vue';
import EpiEditButton from '~/components/globals/EpiEditButton.vue';

type Keys = keyof typeof PopUpTypes;
type PopUpValues = typeof PopUpTypes[Keys];

const globalContentStore = useGlobalContentStore();
const config = useGlobalContentStore().config;
const uiStore = useUiStore();
const { isMobile, isTablet, forceHideAgeGate } = storeToRefs(uiStore);
const pageStore = usePageContentStore();
const { basketOffset, subscriptionOffset } = storeToRefs(globalContentStore);
const { page, pageTypeString } = storeToRefs(pageStore);
const pageType = ref(useGlobalContentStore().pageType);
const useTakeOverHeader = ref(useGlobalContentStore().useTakeOverHeader);
const useCampaignHeader = ref(useGlobalContentStore().useCampaignHeader);

const counter = ref(0);
const isPopupBoxOpen = ref(false);
const popUpType = ref('');
const errorBarText = ref('');
const popUpData = ref({});
const shouldShowErrorBar = ref(false);
const marketModalOpen = ref(false);
const wrongCountryModalOpen = ref(false);
const newsletterModalHaveBeenClosed = useCookie('have_displayed_newsletter_modal', {
  maxAge: 3 * 60 * 60 * 24,
  path: '/',
});;
const showNewsletterModalLoaded = ref(false);
const cursorLeftScreen = ref(false);
const testModeActive = useCookie('test_order_active');
const isPopupAddedToCartBoxOpen = ref(false);
let popUpTimeOut = setTimeout(() => {}, 0);
const { $listen, $event, $lang } = useNuxtApp();

// Detects mobile
const updateWidth = () => {
  uiStore.setIsMobile(window.innerWidth <= 767);
  uiStore.setIsTablet(window.innerWidth > 640 && window.innerWidth <= 1024);
};

const hasScrolled = ref(false);
const handleScroll = () => {
  if (config.currentMarket?.enableZendeskChatWidget && !hasScrolled.value) {
    hasScrolled.value = true;
  }
};

onBeforeMount(async () => {
  document.body.classList.add(`theme-${config.theme}`);
});

onMounted(() => {
  setWindowHeight();
  window.addEventListener('resize', setWindowHeight);
  updateWidth();
  window.addEventListener('resize', updateWidth);
  window.addEventListener('scroll', handleScroll);

  $listen('hide-error-bar', (text) => {
    shouldShowErrorBar.value = false;
  });

  $listen('open-market-modal', () => {
    marketModalOpen.value = true;
  });

  $listen('close-pop-up', () => {
    isPopupBoxOpen.value = false;
    isPopupAddedToCartBoxOpen.value = false;
    document.body.classList.remove('locked');
    popUpType.value = '';
  });

  $listen('trigger-error-bar', (text) => {
    const message = text || $lang('sharedResources', 'genericError');
    shouldShowErrorBar.value = true;
    errorBarText.value = message.errorMessage || message.message;
  });
  
  $listen('open-pop-up', (data) => {
    clearTimeout(popUpTimeOut);
    if (!data.data) {
      return $event('trigger-error-bar', $lang('sharedResources', 'genericError'));
    }

    if (
      (data.type === PopUpTypes.Basket || data.type === PopUpTypes.Subscription) &&
      data.type === popUpType.value
    ) {
      isPopupBoxOpen.value = false;
      document.body.classList.remove('locked');
      popUpType.value = '';
    } else {
      isPopupBoxOpen.value = true;
      document.body.classList.add('locked');

      popUpType.value = data.type;
      if (
        data.type === PopUpTypes.BasketItemAdded ||
        data.type === PopUpTypes.SubscriptionItemAdded
      ) {
        popUpData.value = data.data;

        popUpTimeOut = setTimeout(() => {
          isPopupBoxOpen.value = false;
          document.body.classList.remove('locked');
          popUpType.value = '';
          popUpData.value = {};
        }, 6000);
      } else {
        window.dataLayer?.push({
          event: 'Cart',
          eventCategory: 'cart',
          eventAction: 'view',
        });
      }
    }
  });

  $listen('open-added-to-cart-pop-up', (data) => {
    clearTimeout(popUpTimeOut);

    if (!data.data) {
      return $event('trigger-error-bar', $lang('sharedResources', 'genericError'));
    }

    if (
      (data.type === PopUpTypes.Basket || data.type === PopUpTypes.Subscription) &&
      data.type === popUpType.value
    ) {
      isPopupAddedToCartBoxOpen.value = false;
      popUpType.value = '';
    } else {
      isPopupAddedToCartBoxOpen.value = true;

      popUpType.value = data.type;
      if (
        data.type === PopUpTypes.BasketItemAdded ||
        data.type === PopUpTypes.SubscriptionItemAdded
      ) {
        popUpData.value = data.data;

        popUpTimeOut = setTimeout(() => {
          isPopupAddedToCartBoxOpen.value = false;
          document.body.classList.remove('locked');
          popUpType.value = '';
          popUpData.value = {};
        }, 6000);
      } else {
        window.dataLayer?.push({
          event: 'Cart',
          eventCategory: 'cart',
          eventAction: 'view',
        });
      }
    }
  });
  checkIfWrongCountry();

  setTimeout(() => {
    showNewsletterModalLoaded.value = true;
  }, 3000);

  loadZendeskChat();
});

// Creates a css variable for 100vh that works on ios safari
const setWindowHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const addToCartConfirmationDuration = computed(() => {
  return config.currentMarket?.addToCartConfirmationDuration
    ? config.currentMarket?.addToCartConfirmationDuration * 1000
    : 3000;
});

const isEditMode = computed(() => {
  return route.query.epieditmode === 'false' || route.query.epieditmode === 'true';
});

const isAgeVerificationPage = computed(() => {
  return pageType.value === 'AgeVerificationPage';
});

const isCheckout = computed(() => {
  return pageType.value === 'CheckoutPage';
});

const popUpOffset = computed(() => {
  if (
    popUpType.value === PopUpTypes.Basket ||
    popUpType.value === PopUpTypes.BasketItemAdded
  ) {
    return basketOffset.value;
  } else if (
    popUpType.value === PopUpTypes.Subscription ||
    popUpType.value === PopUpTypes.SubscriptionItemAdded
  ) {
    return subscriptionOffset.value;
  } else {
    return 0;
  }
});

const triggerNewsletterModal = computed(() => {
  if (
    config.newsletterModal?.clicksToTrigger != null &&
    counter.value >= config.newsletterModal?.clicksToTrigger
  ) {
    return true;
  }
  return false;
});

const newsletterCookie = useCookie('have_displayed_newsletter_modal');
const showNewsletterModal = computed(() => {
  if (pageType.value === 'Startpage') return false;
  
  if (page?.value && page?.value.HideNewsletterModal || config.hasSubscribedToNewsletter) return false;

  
  if (
    config.newsletterModal?.isActive != null &&
    !newsletterCookie.value
  ) {
    newsletterModalHaveBeenClosed.value = newsletterCookie.value;
    return config.newsletterModal?.isActive;
  }
  return false;
});

const ageGateCookie = useCookie('age_gate_market');
const showAgeGate = computed(() => {
  return (
    !ageGateCookie.value &&
    config.markets && 
    config.markets.length > 0 &&
    !forceHideAgeGate.value
  );
});

const ageGateImageUrl = computed(() => {
  if (isMobile.value && config.ageGateImageUrlMobile) {
    return config.ageGateImageUrlMobile;
  }
  return config.ageGateImageUrl ?? '';
});

const ageGateUnder18LinkUrl = computed(() => {
  return config.ageGateUnder18LinkUrl;
});

const suggestedMarket = computed(() => {
  const currentIpCountry = config.ipCountry;

  const suggestedMarketVar = config.markets.find(
    (market) => market.countryCode === currentIpCountry
  );

  return suggestedMarketVar;
});

const isEditOrPreviewMode = computed(() => {
  const route = useRoute();
  return route.query.epieditmode === 'false' || route.query.epieditmode === 'true';
});


// Load the Zendesk script with a 15s delay, as it's impacting the Lighthouse score
const loadZendeskChat = async () => {
  setTimeout(() => {
    const script = document.createElement('script');
    script.innerHTML = `window.zEmbed ||
    (function () {
      var queue = [];
      window.zEmbed = function () {
        queue.push(arguments);
      };
      window.zE = window.zE || window.zEmbed;
      document.zendeskHost = 'swedishmatch.zendesk.com';
      document.zEQueue = queue;
    })();`;

    const scriptFramework = document.createElement('script');
    scriptFramework.src = 'https://assets.zendesk.com/embeddable_framework/main.js';
    script.setAttribute('data-ze-csp', 'true');
    scriptFramework.async = true;
    
    document.head.appendChild(script);
    document.head.appendChild(scriptFramework);

    window.zdonload = setInterval(() => {
      if (typeof zE !== 'undefined' && typeof zE.activate !== 'undefined') {
        clearInterval(window.zdonload);
      }
  }, 50, null);
  }, 15000);
};

// close menu when route change
const route = useRoute();

watch(
  () => route.fullPath,
  () => {
    closeOverlay();
    closeErrorBar();
  }
);

watch(
  () => pageTypeString.value,
  () => {
    pageType.value = pageTypeString.value;
    const pageContent = page.value.Content.items[0];
    useTakeOverHeader.value = pageContent.TakeOverHeader ? pageContent.TakeOverHeader : false;
    useCampaignHeader.value = pageContent.CampaignHeader ? pageContent.CampaignHeader : false;
  }
);

onUnmounted(() => {
  window.removeEventListener('resize', setWindowHeight);
  window.removeEventListener('resize', updateWidth);
  document.body.classList.remove('noScroll');
});

const closeOverlay = () => {
  isPopupBoxOpen.value = false;
  isPopupAddedToCartBoxOpen.value = false;
  document.body.classList.remove('locked');
  clearTimeout(popUpTimeOut);
};

const closeErrorBar = () => {
  shouldShowErrorBar.value = false;
  errorBarText.value = '';
};

const closeAgeGate = () => {
  uiStore.setForceHideAgeGate(false);
};

const onCloseMarketModal = () => {
  marketModalOpen.value = false;
};

const onCloseWrongCountryModal = () => {
  document.body.classList.remove('locked');
  wrongCountryModalOpen.value = false;
};

const onCloseNewsletterModal = () => {
  document.body.classList.remove('locked');
  newsletterModalHaveBeenClosed.value = 'true';
};

const checkIfWrongCountry = () => {
  const savedCountriesJSON =
    sessionStorage.getItem('ignored-wrong-country') || '[]';
  const savedCountries = savedCountriesJSON || '[]';
  const countries = JSON.parse(savedCountries);

  if (
    config.isWrongCountry &&
    config.markets?.length > 1 &&
    (config.currentMarket?.enablePurchases || suggestedMarket.value) &&
    !countries.includes(config.currentMarket?.countryCode) &&
    !isEditOrPreviewMode.value
  ) {
    wrongCountryModalOpen.value = true;
  }
};

const mouseleave = () => {
  if (showNewsletterModal.value) {
    cursorLeftScreen.value = true;
  }
};

const setTestMode = (value: string) => {
  testModeActive.value = value;
};

const footer = ref();
const deactivateTestMode = () => {
  if (footer.value) {
    footer.value.toggleTestMode();
  }
};

const clearPopupTimeout = () => {
  clearTimeout(popUpTimeOut);
};

const setPopupTimeout = () => {
  popUpTimeOut = setTimeout(() => {
    isPopupAddedToCartBoxOpen.value = false;
    popUpType.value = '';
    popUpData.value = {};
  }, addToCartConfirmationDuration.value);
};

const showEditButton = computed(() => {
  if (isEditOrPreviewMode.value) {
    return false;
  }
  const { showEditButton } = config;
  return showEditButton;
});

</script>

<style>
.main {
  @apply min-h-screen flex flex-col;
}
.snack-bar-enter-active {
  transition: all 0.3s ease;
}
.snack-bar-leave-active {
  transition: all 0.3s;
}
.snack-bar-enter,
.snack-bar-leave-to {
  transform: translateY(-300px);
}
.locked .z-errorBar {
  top: 0 !important;
}
.min-h-screen-default {
  min-height: calc(100vh - 200px);
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

/* Zendesk button styling */

.Arrange {
    box-sizing: border-box;
    display: table;
    margin: 0;
    min-width: 100%;
    padding: 0;
    table-layout: auto;
}

.u-textNoWrap {
    white-space: nowrap !important;
}

.u-textLeft {
    text-align: left !important;
}
.u-isActionable {
    cursor: pointer !important;
}
.u-inlineBlock {
    display: inline-block !important;
    max-width: 100%;
}

.u-userLauncherColor:not([disabled]) {
  background-color: #145f4b !important;
  color: #FFFFFF !important;
  fill: #FFFFFF !important;
}

.u-borderNone {
    border: none !important;
}

.Arrange-sizeFit {
    vertical-align: middle;
}
.Arrange--middle .Arrange-sizeFill, .Arrange--middle .Arrange-sizeFit {
    vertical-align: middle;
}
.icon-3E9qF {
  padding-right: 0.57143rem;
}
.Icon-2SEmO {
  padding-right: 0.85714rem;
}
.container-3PFIa {
  display: inline-block;
}
.Arrange-sizeFill, .Arrange-sizeFit {
  display: table-cell;
  padding: 0;
  vertical-align: top;
}
.u-textInheritColor {
  color: inherit !important;
}
.u-inlineBlock {
  display: inline-block !important;
  max-width: 100%;
}

.u-textBold {
  font-weight: 700;
}

.zendesk-button {
  color: #fff;
  fill: #fff;
  padding: 11px 15px 13px 15px;
  border-radius: 999rem;
  bottom: 0;
  letter-spacing: 0.6px;
  font-size: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu;
  height: 46px;
  @apply sm:pl-[22px] sm:pr-[22px];
}

.icon-3E9qF {
  padding-right: 7px;
}

.Arrange--middle .Arrange-sizeFit {
  vertical-align: middle;
}

.label-3kk12 {
  font-size: 15px;
}

.Icon-2SEmO svg {
  min-width: 20px;
  min-height: 20px;
  height: 19.9884px;
  width: 19.9884px
}

.zendesk-button-wrapper {
  font-feature-settings: "kern", "kern";
  font-kerning: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif;
}

.zendesk-button-wrapper button:focus {
  outline: none;
  box-shadow: inset 0 0 0 0.21429rem rgb(255 255 255 / 40%) !important;
}
</style>
